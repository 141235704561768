import React from "react";
import { Link } from "gatsby";

const Navi = ({ navItems }) => {
   return (
      <nav className="mt-4 w-full sm:w-4/12 md:w-3/12 pr-6 flex-shrink-0 sm:sticky sm:top-6">
         <ul className="m-0">
            {navItems.navs &&
               navItems.navs.length > 0 &&
               navItems.navs.map((currentEdge) => (
                  <li>
                     <span className="text-gray-500 text-sm uppercase">{currentEdge.title}</span>
                     <ul className="mb-8">
                        {currentEdge.links.map((currentNode) => (
                           <li>
                              <Link to={`/${currentNode.link.cached_url}`} className="text-gray-800 hover:underline focus:underline active:underline">
                                 {currentNode.link.story.name}
                              </Link>
                           </li>
                        ))}
                     </ul>
                  </li>
               ))}
         </ul>
      </nav>
   );
};

export default Navi;
