import React from "react";
import { Link } from "gatsby";
import SbEditable from "storyblok-react";
import { getButtonTheme } from "../../../helpers/theme/color";

// TODO: Add target _blank on all external link

const Button = ({ blok, extraClass, blockBackground }, ...props) => {
   const buttonTheme = getButtonTheme(blok.button_color, blok.button_type, blockBackground);

   return blok.link.linktype === "story" ? (
      <SbEditable content={blok}>
         <span className={`block text-${blok.button_align}`}>
            <Link to={`/${blok.link.cached_url}`} className={`w-full sm:w-auto inline-flex items-center justify-center px-8 py-2 border-transparent text-base transition duration-150 ease-in-out md:text-lg md:px-10 border-4 no-underline hover:no-underline focus:no-underline font-bold ${extraClass} ${buttonTheme.renderedClass}`} data-block="button">
               {blok.title}
            </Link>
         </span>
      </SbEditable>
   ) : (
      <SbEditable content={blok}>
         <span className={`block text-${blok.button_align}`}>
            <a href={blok.link.url} className={`w-full sm:w-auto inline-flex items-center justify-center px-8 py-2 border-transparent text-base transition duration-150 ease-in-out md:text-lg md:px-10 border-4 no-underline hover:no-underline focus:no-underline font-bold ${extraClass} ${buttonTheme.renderedClass}`} data-block="button" target="_blank" rel="noreferrer">
               {blok.title}
            </a>
         </span>
      </SbEditable>
   );
};

export default Button;
