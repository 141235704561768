const purgeWhitelistPatterns = [];
["red", "orange", "yellow", "green", "blue", "gray"].forEach((color) => {
   purgeWhitelistPatterns.push(new RegExp(`^(?!divide|placeholder)[a-zA-Z:]*-${color}-[0-9]*`));
});

purgeWhitelistPatterns.push(new RegExp(`^(?!divide|placeholder)[a-zA-Z:]*-white*`));

module.exports = {
   future: {
      removeDeprecatedGapUtilities: true,
      purgeLayersByDefault: true,
   },
   purge: {
      layers: ["components", "utilities"],
      content: ["./src/**/*.js"],
      options: {
         whitelistPatterns: purgeWhitelistPatterns,
      },
   },
   theme: {
      fontFamily: {
         sans: ["Nunito Sans", "sans-serif"],
      },
      extend: {
         colors: {
            green: {
               100: "#a5ffa2",
               200: "#87ea86",
               300: "#69cc6b",
               400: "#4baf50",
               500: "#2a9336",
               600: "#00771b",
               700: "#005c00",
               800: "#004200",
               900: "#002b00",
            },
            blue: {
               100: "#acdcff",
               200: "#8ebfff",
               300: "#70a3eb",
               400: "#5087cd",
               500: "#2e6db0",
               600: "#005494",
               700: "#003c78",
               800: "#00265e",
               900: "#001045",
            },
            yellow: {
               100: "#ffea39",
               200: "#ffcd03",
               300: "#deb000",
               400: "#be9500",
               500: "#9e7a00",
               600: "#806000",
               700: "#654700",
               800: "#4e3000",
               900: "#3c1800",
            },
            orange: {
               100: "#ffda5a",
               200: "#ffbc3c",
               300: "#f6a019",
               400: "#d58500",
               500: "#b56a00",
               600: "#955000",
               700: "#773800",
               800: "#5d1f00",
               900: "#470400",
            },
            red: {
               100: "#ffa491",
               200: "#ff8676",
               300: "#ff685c",
               400: "#f44843",
               500: "#d2252b",
               600: "#b00014",
               700: "#900000",
               800: "#700000",
               900: "#540000",
            },
         },
         whitespace: {
            "break-spaces": "break-spaces",
         },
         height: {
            "px-380": "380px",
         },
         minHeight: {
            "px-380": "380px",
         },
         maxWidth: {
            "21ch": "21ch",
            "px-808": "808px",
            "px-950": "950px",
         },
         spacing: {
            "16/9": "56.25%",
         },
         scale: {
            "i-100": "-1",
         },
         zIndex: {
            996: 996,
            997: 997,
            998: 998,
            999: 999,
         },
      },
      mk: {
         colors: {
            mainKey: {
               green: 400,
               blue: 500,
               yellow: 200,
               orange: 300,
               red: 500,
            },
         },
      },
      typography: {
         default: {
            css: {
               a: {
                  color: null,
               },
            },
         },
      },
   },
   variants: {
      width: ["responsive", "hover", "focus"],
      height: ["responsive", "hover", "focus"],
      padding: ["responsive", "hover", "focus"],
      inset: ["responsive", "hover", "focus"],
   },
   plugins: [require("@tailwindcss/ui")],
};
